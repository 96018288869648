import "./s3.css";
import EE from '../../fx/MI1.png';
import M2 from '../../fx/MI2.png';
import M3 from '../../fx/MI3.png';

function S3() {
  return (
    <div className="s3">
      <h2 className="s3h2">
        Existing Challenges
      </h2>
      <div className="s3block1">
        <img className="s3mi" src={EE} />
        <h3 className="s3title">
          Expensive Equity
        </h3>
        <p className="s3p">
          Difficult and expensive to buy equities outside of an individual's home jurisdiction.
        </p>
      </div>

      <div className="s3block2">
        <img className="s3mi" src={M2} />
        <h3 className="s3title">
          Portfolio Management
          Limitations
        </h3>
        <p className="s3p">
          Inefficient to manage a portfolio containing both crypto as well as equity.
        </p>
      </div>

      <div className="s3block3">
        <img className="s3mi" src={M3} />
        <h3 className="s3title">
          Product Limitations
        </h3>
        <p className="s3p">
          Investors are limited to  buying whole units of stock which can limit their access to higher-priced individual shares.
        </p>
      </div>
    </div>
  );
}

export default S3;
