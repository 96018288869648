import { ethers } from 'ethers';
import ABI from './ABI.json'



const SWAP_CONTRACT = '0x536891aEA1A1d15ad7F9816A69CE2E9D6909A167';
const WDTPADDRESS = '0x09022Cb84f32D610863668ef6662B366dA16B461';
const USDT = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F';
const USDC = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';

export async function checkMetaMaskConnected() {
	if (window.ethereum && window.ethereum.isMetaMask) {
		try {
			console.log(ABI);
			// Request access to the user's accounts
			const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
			// Get the current network ID
			let provider = new ethers.BrowserProvider(window.ethereum)

			// Request MetaMask to add the Matic network
			try {
				await window.ethereum.request({
					method: 'wallet_addEthereumChain',
					params: [
						{
							chainId: '0x89',
							chainName: 'Polygon Mainnet',
							nativeCurrency: {
								name: 'Polygon Mainnet',
								symbol: 'MATIC',
								decimals: 18,
							},
							rpcUrls: ['https://polygon-rpc.com/'],
							blockExplorerUrls: ['https://polygonscan.com/'],
						},
					],
				});
				console.log('Matic network added to MetaMask');
			} catch (error) {
				throw new Error('Failed to add Matic network to MetaMask');
			}

			// Request the user to switch to the Polygon network
			await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: '0x89' }] }); // 0x89 is the chain ID for Polygon Mainnet

			const network = await provider.getNetwork();
			let res = network && network.name !== 'unknown';
			console.log(provider.getSigner());
			return { 'res': res, 'address': (await provider.getSigner()).address };
		} catch (error) {
			// User denied account access or something went wrong
			window.alert('Failed to connect to MetaMask:', error);
			return false;
		}
	} else {
		// MetaMask is not installed or not detected
		window.alert('MetaMask not detected');
		return false;
	}
}



export async function checkAllowance(txInf, _amount) {
	try {
		let provider = new ethers.BrowserProvider(window.ethereum);
		const parts = txInf.split('.');
		const baseContractAddress = (parts[0] === 'WDTP' ? WDTPADDRESS : (parts[0] === 'USDT') ? USDT : USDC);
		const decimal = (parts[2] === '0' ? 6 : 6);
		const contract = new ethers.Contract(baseContractAddress, ABI.ERC20, await provider.getSigner());
		console.log((await provider.getSigner()).address, baseContractAddress);
		let allowance = await contract.allowance((await provider.getSigner()).address, SWAP_CONTRACT);
		const amount = ethers.formatUnits(allowance, decimal);
		console.log(amount, _amount, decimal);

		if ((parseFloat(amount)) >= _amount) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		console.error('Error fetching allowance:', error);
	}
}



export async function Approve(txInf, amount) {

	try {
		let provider = new ethers.BrowserProvider(window.ethereum);
		const parts = txInf.split('.');
		const baseContractAddress = (parts[0] === 'WDTP' ? WDTPADDRESS : (parts[0] === 'USDT') ? USDT : USDC);
		const decimal = (parts[2] === '0' ? 6 : 6);
		const contract = new ethers.Contract(baseContractAddress, ABI.ERC20, await provider.getSigner());
		console.log(amount, '   ', ethers.parseUnits(amount.toString()));
		let txn = await contract.approve(SWAP_CONTRACT, ethers.parseUnits(amount.toString(), decimal));
		await txn.wait();
		console.log(txn.hash);
		if (txn.hash != null) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		if (error.code === 4001) {
			// The user rejected the transaction
			console.log('Transaction rejected by user.');
		} else {
			// Handle other errors
			console.error('Error sending transaction:', error);
		}
	}
}


export async function swap(txInf, amount) {
	try {
		console.log(txInf)
		let provider = new ethers.BrowserProvider(window.ethereum);
		const parts = txInf.split('.');
		const IP_SHARE_TOKEN = (parts[0] === 'WDTP' ? WDTPADDRESS : '');
		const CRYPTO_TOKEN = (parts[1] === 'USDT' ? USDT : USDC);
		const normal = (parts[2] === '0' ? true : false);
		const decimal = (parts[2] === '0' ? 6 : 6);
		const contract = new ethers.Contract(SWAP_CONTRACT, ABI.SWAPCONTRACT, await provider.getSigner());
		let txn = await contract.swap(CRYPTO_TOKEN, IP_SHARE_TOKEN, ethers.parseUnits(amount.toString(), decimal), normal);
		await txn.wait();
		console.log(txn.hash);
		if (txn.hash != null) {
			return true;
		} else {
			return false;
		}
	}
	catch (error) {
		console.log(error);
		if (error.code === 4001) {
			// The user rejected the transaction
			console.log('Transaction rejected by user.');
		} else {
			// Handle other errors
			console.error('Error sending transaction:', error);
		}
	}
}