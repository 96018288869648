import React from 'react';
import './tradingview.css'; // Link to your custom CSS

const Tradingview = () => {
  return (
    <div className="tv-page">
      <div className="tv-container">
        <section className="tv-content">
          <h1>TradingView</h1>
          <p>
            <a href="https://tr.tradingview.com/economic-calendar/" target="_blank" rel="noopener noreferrer">Economic calendar</a> is an essential tool for forex investors to develop profitable trading strategies. Learning how to read the economic calendar can help traders predict market movements. Educational resources to effectively use the economic calendar can be accessed online. Understanding economic calendar news on TradingView can make your investment decisions more informed. The economic calendar is a fundamental risk management tool for traders. <a href="https://www.tradingview.com/markets/currencies/economic-calendar/" target="_blank" rel="noopener noreferrer">US Dollar Index</a>, is a critical indicator for forex market investors. You can learn how to buy DXY to diversify your portfolio.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Tradingview;