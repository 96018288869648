import "./footer.css";
import "./lowerFooter.css";
import "./Quicklinks.css";
import logo from '../../fx/logo.svg'


function Footer() {
  return (
    <div className="footer">
      <div>
        <div className="fb1">
          <img src={logo} alt="Logo" />
          <h3>
            a bridge between Trade Equities, FX,
            commodities and Blockchain
          </h3>
        </div>
        <div className="fb2">
          {/* <QuickLinks /> */}
        </div>
        <div className="fb3">
          <h1>Contact Us</h1>
          <p>
            Subscribe to get update and notify
            our exchange and products
          </p>
        </div>
      </div>
      <LowerFooter />
    </div>
  );
}

export default Footer;






function LowerFooter() {
  return (
    <div className="lowerfooter">
      <div className="lowerFooterLine">
        <h1 className="lowerFooterCopyright">
          WDTP.io ©. All rights reserved.
        </h1>
        <h1 className="lowerFooterLinks">
          <span>
            Terms of Service</span> | <span>
            Privacy Policy
          </span>
        </h1>
      </div>
    </div>
  );
}




const QuickLinks = () => {
  return (
    <div className="quick-links-container">
      <div className="column">
        <h2 className="column-heading">Quick Links</h2>
        <ul className="link-list">
          <li><a href="#">About Us</a></li>
          <li><a href="#">Our Team</a></li>
          <li><a href="#">Pricing</a></li>
        </ul>
      </div>
      <div className="column2">
        <ul className="link-list">
          <li><a href="#">About us</a></li>
          <li><a href="#">Our team</a></li>
          <li><a href="#">Pricing</a></li>
        </ul>
      </div>
    </div>
  );
};