import React from 'react';
import './header.css'
import logo from '../../fx/logo.svg'
const Header = () => {

  const swapClick = () => {
    
    window.location.href = '/swap-page';
  };

  return (
    <header>
     <div>
        <img  className="logo" src={logo} alt="Logo" />
      </div>
      <nav>
        <ul className="xnav-links">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/our-team">Our Team</a>
          </li>
          <li>
            <a href="/about-us">About Us</a>
          </li>
          <li>
            <a href="/whitepaper">Whitepaper</a>
          </li>
          <li>
            <a href="/tradingview">Trading View</a>
          </li>
          <li>
            <button onClick={swapClick} className='swapBtn'>Swap</button>
          </li>
        </ul>
      </nav>
      
    </header>
  );
};

export default Header;
