import "./s4.css";
import I1 from '../../fx/S4I1.png'
import I2 from '../../fx/S4I2.png'
import I3 from '../../fx/S4I3.png'


function S4() {
  return (
    <div className="s4">
      <div className="S4header">
      <h2 >
        <span className="s4h21">
          Digital Trading Platform
        </span>
        <span className="s4h22">
          is the solution
        </span>
      </h2>
      </div>

      <div className="s4bA">
        <h1>
          Global Access, Zero KYC
        </h1>
        <p>
          Retail and institutional investors can trade IP-Shares backed by Stocks, Commodities, other Assets without geographical restrictions
        </p>
        <img src={I1} />
      </div>

      <div className="s4bB">
        <h1>
          Improved Portfolio
          Management
        </h1>
        <p>
          Retail and institutional investors can trade IP-Shares backed by Stocks, Commodities, other Assets without geographical restrictions
        </p>
        <img src={I2} />
      </div>

      <div className="s4bC">
        <h1>
          Crowd Funding
        </h1>
        <p>
          With IP-Shares encrypted by tokens, investors can do fractional ownership
        </p>
        <img src={I3} />
      </div>

    </div>
  );
}

export default S4;
