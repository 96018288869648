import './s1.css';
import gp from '../../fx/gp.png';
import as from '../../fx/AppleStore.png';


function S1() {

    const App = () => {
        const newUrl = 'https://app.wdtp.io/login'; 
        window.open(newUrl, '_blank');
      };

      const AppleStore =()=>{
        const url = 'https://apps.apple.com/gb/app/4nx/id1619151775';
        window.open(url,'_blank');
      }

      const GoogleStore =()=>{
        const url = 'https://play.google.com/store/apps/details?id=com.unity.mobile.fournx&gl=GB';
        window.open(url,'_blank');
      }


    return (
        <div className='s1'>
            <h1 className='sh1'>World First Trading Platform of</h1>
            <h1 className='sh2'>Digitalized Intellectual Property</h1>
            <h2 className='sh3'>Shares encrypted by Tokens for Different Types of Assets</h2>
            <div className="row">
                <button onClick={App}>Launch App</button>
                <img onClick={GoogleStore} className='gp' src={gp} alt="Google Play Store" />
                <img onClick={AppleStore} className='as' src={as} alt="Apple Store" />
            </div>
        </div>
    );
}

export default S1;