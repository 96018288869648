import "./s6.css";
import MI from "../../fx/S6MI.svg";
import MI2 from "../../fx/S6MI2.png";

function S6() {
  return (
    <div className="s6">
      <div className="S6header">
        <span className="s6t11">How the</span>
        <span className="s6t12">Stock Tokenization works</span>
      </div>

      <div>
        <div className="s6MI3">
          <img src={MI2} />
        </div >
        <div className="s6MI1">
          <img src={MI} alt="MI" />
          <h2>Individuals \ Institutions</h2>
          <p>Trade and exchange IP -shares worldwide in full compliance with international and local laws</p>
        </div>

        <div className="s6MI2">
          <img src={MI} alt="MI" />
          <h2>Encrypted by tokens assets</h2>
          <p>IP-Shares can be freely traded on our platform. To burn an IP-Shares, the IPS Owner can burn the amount initially issued to receive the locked collateralized asset</p>
        </div>
      </div>
    </div>
  );
}

export default S6;
