import "./s5.css";
import S5I from '../../fx/S5MI.png';


function S5() {
  return (
    <div className="s5">
      <div className="S5header">
        <span className="t11">
          Core Benefits
        </span>
        <span className="t12">
          of Our Ecosystem
        </span>
      </div>
      <img src={S5I} />
      <div className="S5header2">
        <h2 className="h21">
          Trade and exchange IP -shares worldwide in full compliance with international and local laws
        </h2>
        <h2 className="h22">
          Intellectual Property is not regulated by US Securities and Exchange Commission US SEC
        </h2>
        <h2 className="h23">
          Possibility to receive payments (investments) in fiat money and cryptocurrency
        </h2>
      </div>
    </div>
  );
}

export default S5;
