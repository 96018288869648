import "./sp.css";
import "./dd.css";
import usdx from "../../fx/usdx.png";
import arrow from "../../fx/ArrowBtn.png";
import React, { useState, useRef, useEffect } from "react";
import {
  checkMetaMaskConnected,
  checkAllowance,
  Approve,
  swap,
} from "../../web3/web3";

function SP() {
  const [value, setValue] = useState();
  const [isRotated, setIsRotated] = useState(true);
  const imageClassName = isRotated ? "sp_cp_img rotated" : "sp_cp_img";
  const [SelectedIPToken, setIPToken] = useState("WDTP");
  const BaseIPTokens = ["WDTP"];
  const [SelectedCrypto, setCrypto] = useState("USDT");
  const cryptoFiat = ["USDT"];
  const option1 = useRef(null);
  const option2 = useRef(null);
  const [main_btn_Label, set_Main_btn_Label] = useState("Connect Wallet");
  const [wallet, set_Wallet] = useState(
    "0x0000000000000000000000000000000000000000"
  );
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      const clickedElement = event.target;
      if (
        !option1.current.contains(clickedElement) &&
        !option2.current.contains(clickedElement) &&
        clickedElement.tagName !== "INPUT" &&
        clickedElement.tagName !== "BUTTON" &&
        clickedElement.tagName !== "A"
      ) {
        event.preventDefault();
        if (isRotated) {
          option1.current.focus();
        } else {
          option2.current.focus();
        }
      }
    }
    document.addEventListener("click", handleClickOutside);
    option1.current.focus();
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isRotated, option1, option2]);

  const BaseIPTokensSelect = (value) => {
    setIPToken(value);
  };
  const CryptoTokensSelect = (value) => {
    setCrypto(value);
  };






  const handleChange = async (event) => {
    const numericValue = event.target.value
      .replace(/[^0-9.]/g, "")
      .slice(0, 20);
    setValue(numericValue);

    if (isConnected) {
      if (!isRotated) {
        const route =
        SelectedIPToken + "." + SelectedCrypto + ".1";
        setIsLoading(true);
        let res = await checkAllowance(route,numericValue);
        if (res) {
          set_Main_btn_Label("Swap");
          setIsLoading(false);
        } else {
          set_Main_btn_Label("Approve");
          setIsLoading(false);
        }
      } 
      else {
        const route = SelectedCrypto + "." + SelectedIPToken +".0";
        setIsLoading(true);
        let res = await checkAllowance(route,numericValue);
        if (res) {
          set_Main_btn_Label("Swap");
          setIsLoading(false);
        } else {
          set_Main_btn_Label("Approve");
          setIsLoading(false);
        }
      }
    }
  };

  const handleClick = () => {
    setIsRotated(!isRotated);
    setValue("");
  };

  const handleConnectMetaMask = async () => {
    if (!isConnected) {
      const isConnected = await checkMetaMaskConnected();
      setIsConnected(isConnected.res);
      if (isConnected.res) {
        set_Main_btn_Label("Approve");
        set_Wallet(isConnected.address);
      }
    } else {
      if (main_btn_Label === "Approve") {
        console.log(value);

        if (isRotated) {
          const route = SelectedCrypto + "." + BaseIPTokens +  ".0";
          setIsLoading(true);

          let res = await Approve(route,value);
          if (res) {
            set_Main_btn_Label("Swap");
            setIsLoading(false);
          } else {
            set_Main_btn_Label("Approve");
            setIsLoading(false);
          }
        }
        else {
         
          const route =
          BaseIPTokens + "." + SelectedCrypto + ".1";
          setIsLoading(true);

          let res = await Approve(route,value);
          if (res) {
            set_Main_btn_Label("Swap");
            setIsLoading(false);
          } else {
            set_Main_btn_Label("Approve");
            setIsLoading(false);
          }
        }
      } else if (main_btn_Label === "Swap") {//SWAP
       
        
        if (isRotated) {
          const route = BaseIPTokens + "." + SelectedCrypto +  ".0";
          setIsLoading(true);

          let res = await swap(route,value);
          if (res) {
            setValue('0')
             setIsLoading(false);
           } 
           else {
             setValue('0')
             setIsLoading(false);
           }
        }
        else {
          const route = BaseIPTokens + "." + SelectedCrypto +  ".1";
          setIsLoading(true);
          let res = await swap(route,value);
          if (res) {
           setValue('0')
            setIsLoading(false);
          } 
          else {
            setValue('0')
            setIsLoading(false);
          }
        }
      }
    }
  };

  return (
    <div className="sp">
      <div className="sp_panel">
        <h1>Trade token in an instant</h1>
        <h3> CONNECTED TO: {wallet}</h3>
        <div className="sp_line"></div>

        <Option_1 valueArray={cryptoFiat} onSelect={CryptoTokensSelect} />
        <input
          type="text"
          value={value}
          onChange={handleChange}
          className="IFS"
          placeholder="0.0"
          disabled={!isRotated || !isConnected}
          ref={option1}
        />
        <div className="sp_NB">
          <img
            className={imageClassName}
            src={arrow}
            alt="Change Positions"
            onClick={handleClick}
          />
        </div>
        <Option_2 valueArray={BaseIPTokens} onSelect={BaseIPTokensSelect} />
        <input
          type="text"
          value={value}
          onChange={handleChange}
          className="IFS2"
          placeholder="0.0"
          disabled={isRotated || !isConnected}
          ref={option2}
        />

        <div className="sp_cas">
          <div className={`'' ${isLoading ? "loading-spinner" : ""}`} />
          <button
            className={`connect-button ${isLoading ? "loading" : ""}`}
            onClick={handleConnectMetaMask}
            disabled={isLoading}
          >
            {main_btn_Label}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SP;

function Option_1({ valueArray, onSelect }) {
  const [selectedValue, setSelectedValue] = useState(valueArray[0]);

  const handleSelect = (value) => {
    setSelectedValue(value);
    onSelect(value);
  };

  return (
    <div className="dropdown">
      <div className="dropdown-header">
        <img src={usdx} alt="Image" className="dropdown-image"></img>
        <span className="dropdown-text">{selectedValue}</span>
      </div>
      <button className="dropdown-button">▼</button>
      <ul className="dropdown-menu">
        {valueArray.map((value, index) => (
          <li key={index} onClick={() => handleSelect(value)}>
            {value}
          </li>
        ))}
      </ul>
    </div>
  );
}







function Option_2({ valueArray, onSelect }) {
  const [selectedValue, setSelectedValue] = useState(valueArray[0]);

  const handleSelect = (value) => {
    setSelectedValue(value);
    onSelect(value);
  };

  return (
    <div className="dropdown2">
      <div className="dropdown-header2">
        <img src={usdx} alt="Image" className="dropdown-image2"></img>
        <span className="dropdown-text2">{selectedValue}</span>
      </div>
      <button className="dropdown-button2">▼</button>
      <ul className="dropdown-menu2">
        {valueArray.map((value, index) => (
          <li key={index} onClick={() => handleSelect(value)}>
            {value}
          </li>
        ))}
      </ul>
    </div>
  );
}
