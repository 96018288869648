import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Components/Header/header';
import S1 from './Components/S1/s1';
import S2 from './Components/S2/s2';
import S3 from './Components/S3/s3';
import S4 from './Components/S4/s4';
import S5 from './Components/S5/s5';
import S6 from './Components/S6/s6';
import Footer from './Components/footer/footer';
import SP from './Components/SwapPage/sp';
import Tradingview from './Pages/TV/tradingview';
import './index.css';
import f from './fx/BG.svg';

function App() {
  return (
    <BrowserRouter>
    <Routes>
        <Route exact path="/" element={<MP />} />
        <Route exact path="/swap-page" element={<SSP />} />
        <Route exact path="/tradingview" element={<TV />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;



function MP() {
  return (
    <div className='container'>
      <img className='bg' src={f} alt="Logo" />
      <div className='main'>
        <Header />
        <S1 /> 
        <S2 />
        <S3 />
        <S4 />
        <S5 />
        <S6 />
        <Footer/>
      </div>
    </div>
  );
}



function SSP() {
  return (
    <div className='SSP'>
      
        <Header />
        <SP/>
     
    </div>
  );
}

function TV() {
  return (
    <div className='SSP'>
      
        <Header />
        <Tradingview/>
     
    </div>
  );
}