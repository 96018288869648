import "./s2.css";
import laptop from "../../fx/Frame.svg";

function S2() {
  return (
    <div className="s2">
      <img className="laptop" src={laptop} />
        <div className="S2header">
        <h1>
          <span className="t1">What is</span>
          <span className="t2">WDTP?</span>
        </h1>
        </div>
      <p className="s2p">
        WDTP decentralised trading platform that offers our users the ability to
        trade IP-Shares collateralized by equities, commodities and and other
        business assets on the blockchain via our minting and trading mechanism
        that mimics the off-chain economics of real world prices.
      </p>
    </div>
  );
}

export default S2;
